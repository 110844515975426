<!--
 * @Author: lbh
 * @Date: 2021-04-23 11:13:14
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-26 16:20:15
 * @Description: 產品介紹
-->
<template>
  <div
    class="products-box"
    :class="type == '900' ? 'products-box_1' : type == '600' ? 'products-box_2' : ''"
  >
    <div
      class="big-title"
      v-if="title"
      v-text="title"
    ></div>
    <div class="items-box">
      <div
        class="items"
        v-for="(item,index) in products"
        :key="index"
      >
        <!-- 邊框1 -->
        <div class="border_1"></div>
        <!-- 邊框2 -->
        <div class="border_2"></div>
        <!-- 內容體 -->
        <div class="content">
          <img :src="item.img" />
          <h3 v-text="`${index+1 < 10 ? `0${index+1}` : index+1}.${item.title}`"></h3>
          <div
            class="text"
            v-text="item.content"
          ></div>
          <sButton
            :index="index"
            :text="$t('products.LearnMore')"
            :go="item.go"
          />
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import sButton from "@/components/commo/button";
import util from "@/js/utils/util";
export default {
  name: "products-box",
  components: { sButton },
  data () {
    return {
      products: [],
    }
  },
  mounted () {
    // 組裝產品介紹
    let products = [
      { title: this.$t('products.QRCodeOrdering.label'), content: this.$t('products.QRCodeOrdering.value'), img: util.getImgPath('icon01.png'), go: '/qrcode' },
      { title: this.$t('products.MembershipSystem.label'), content: this.$t('products.MembershipSystem.value'), img: util.getImgPath('icon02.png'), go: '/vip' },
      { title: this.$t('products.DeliverySelfPickup.label'), content: this.$t('products.DeliverySelfPickup.value'), img: util.getImgPath('icon03.png'), go: '/takeOutOrPickUp' },
      { title: this.$t('products.OnlineBooking.label'), content: this.$t('products.OnlineBooking.value'), img: util.getImgPath('icon04.png'), go: '/reservation' },
      { title: this.$t('products.QueueUpToCollectTickets.label'), content: this.$t('products.QueueUpToCollectTickets.value'), img: util.getImgPath('iocn05.png'), go: '/lineUp' }];
    this.products = products;
  },
  created () { },
  methods: {

  },
  props: {
    title: {
      default: ''
    },
    type: {
      default: '1000'
    }
  }
}
</script>
<style lang="less" scoped>
.products-box_1 {
  padding: 50px 20% !important;
  .items {
    width: 100% !important;
    padding: 60px 4% !important;
  }
}
.products-box_2 {
  padding: 50px 20px !important;
  .items {
    width: 100% !important;
    padding: 60px 4% !important;
  }
}
.products-box {
  padding: 50px 10%;
  .big-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }
  .items-box {
    display: flex;
    flex-wrap: wrap;
  }
  .items {
    position: relative;
    width: calc(25% - 20px);
    padding: 4%;
    margin: 20px 10px;
    &:hover {
      background-color: rgb(237, 237, 237);
    }
    .border_1 {
      position: absolute;
      width: calc(100% - 16px);
      height: 100%;
      left: 8px;
      top: 0;
      border: 1px solid #000;
    }
    .border_2 {
      position: absolute;
      height: calc(100% - 16px);
      width: 100%;
      top: 8px;
      left: 0;
      border: 1px solid #000;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 3;
      height: 100%;
      img {
        margin-bottom: 20px;
      }
      h3 {
        text-transform: capitalize;
        letter-spacing: 0;
        font-size: 30px;
        line-height: 36px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .text {
        font-size: 14px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
